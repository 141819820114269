import { createClasses } from 'styles';
import useAppLayout from 'hooks/useAppLayout';
import { useAuth } from 'context/auth';
import { Outlet, useOutletContext } from 'react-router-dom';
import ExitToAppIcon from '@mui/icons-material/ExitToApp';
import Stack from '@mui/material/Stack';
import IconButton from '@mui/material/IconButton';
import AppBar from 'react-app/components/AppBar';
import AppFrame from 'react-app/components/AppFrame';
import AppBanner from 'components/AppBanner';
import AppBarNav from 'react-app/components/AppBarNav';
import DrawerMenu from 'react-app/components/DrawerMenu';
import ErrorBoundary from 'components/ErrorBoundary';
import ThemeSwitch from 'components/ThemeSwitch';
import ContactIcons from 'components/ContactIcons';
import Brand from 'components/Brand';
import Link from 'components/Link';
import { appData } from 'config/data.cjs';

const DrawerMenuNavItemProps = {
	NavLinkComponent: Link,
	dense: true,
	spacingValue: 0
};

const useClasses = createClasses((theme) => ({
	root: {},
	appBar: {
		...(theme.mixins.backdropBlur(theme.spacing(theme.spacing(1)))),
		backgroundColor: theme.helpers.alpha('background-paper', .8),
		maxWidth: theme.breakpoints.values.desktop,
		alignItems: 'center',
		alignSelf: 'center',
		justifyContent: 'center',
		boxShadow: 'none',
		color: theme.config.palette.text.primary,
		[theme.breakpoints.up('desktop')]: {
			borderRadius: `0 0 ${theme.shape.borderRadius}px ${theme.shape.borderRadius}px`
		},
		[theme.breakpoints.down('desktop')]: {
			borderBottom: `solid 1px ${theme.config.palette.divider}`
		}
	},
	appBarNav: {},
	navButton: {
		borderRadius: theme.spacing(8)
	},
	toolbar: {
		width: '100%',
		paddingX: theme.config.containerSpacing
	},
	toolbarLeft: {
		display: 'flex',
		alignItems: 'center',
		[theme.breakpoints.up('desktop')]: {
			width: 160
		}
	},
	toolbarMiddle: {
		width: '100%',
		display: 'flex',
		justifyContent: 'center',
		paddingX: theme.config.containerSpacing
	},
	toolbarRight: {
		display: 'flex',
		justifyContent: 'flex-end',
		[theme.breakpoints.up('desktop')]: {
			width: 160
		}
	},
	drawerHeader: {
		marginTop: 0,
		[theme.breakpoints.down('lg')]: {
			borderBottom: `solid 1px ${theme.config.palette.divider}`
		}
	},
	headerContent: {
		[theme.breakpoints.down('lg')]: {
			marginLeft: theme.spacing(1)
		}
	},
	social: {
		fontSize: '1.5rem',
		margin: 0
	}
}), {
	name: 'AppBasicLayout'
});

export default function AppBasicLayout(props) {
	const {
		classes: classesProp,
		className,
		children,
		BrandProps,
		BrandLogoComponent,
		appFooterContent = null,
		cookieConsentContent = null,
		...rest
	} = props;

	const classes = useClasses(props);
	const pageData = useOutletContext();
	const { pathname, id: pageId, options = {} } = pageData;
	const [ auth, authActions ] = useAuth();

	const alertData = appData.alert || {};

	const {
		browser,
		drawerOpen,
		drawerRef,
		handleDrawerToggle,
		handleDrawerClose
	} = useAppLayout();

	const { isWidthUp, breakpoint } = browser;
	const lgUp = isWidthUp('lg', breakpoint);
	const mdUp = isWidthUp('md', breakpoint);
	const smUp = isWidthUp('sm', breakpoint);

	function handleLogout() {
		authActions.logoutUser();
	}

	const brand = (
		<Brand {...BrandProps} variant="button" BrandLogoComponent={BrandLogoComponent}/>
	);

	const AppBarProps = {
		classes: {
			root: classes.appBar,
			toolbar: classes.toolbar,
			toolbarLeft: classes.toolbarLeft,
			toolbarMiddle: classes.toolbarMiddle,
			toolbarRight: classes.toolbarRight
		},
		toolbarLeftContent: brand,
		toolbarRightContent: (
			<Stack direction="row" marginRight={{xs: 0, md: -2}}>
				<ContactIcons className={classes.social} shop={false} pinterest={smUp} youtube={false}/>
				{auth.profile?.isAdmin && (
					<IconButton color="inherit" onClick={handleLogout}>
						<ExitToAppIcon/>
					</IconButton>
				)}
				<ThemeSwitch variant="icon"/>
			</Stack>
		),
		toolbarMiddleContent: mdUp && (
			<AppBarNav
				pageId={pageId}
				DrawerMenuNavItemProps={DrawerMenuNavItemProps}
				classes={{
					root: classes.appBarNav,
					button: classes.navButton
				}}
				pathname={pathname}/>
		)
	};

	const AppDrawerProps = {
		ref: drawerRef,
		drawerWidth: 270,
		headerCloseButton: !mdUp,
		headerContent: lgUp ? null : brand,
		variant: 'temporary',
		footerDivider: true,
		fixedFooter: true,
		classes: {
			drawerHeader: classes.drawerHeader,
			headerContent: classes.headerContent
		},
		children: mdUp ? null : (
			<DrawerMenu
				pageId={pageId}
				pathname={pathname}
				onClick={handleDrawerClose}
				DrawerMenuNavItemProps={DrawerMenuNavItemProps}
			/>
		)
	};

	return (
		<AppFrame
			{...rest}
			className={classes.root}
			open={drawerOpen}
			clippedDrawer={lgUp}
			onClose={handleDrawerClose}
			onToggle={handleDrawerToggle}
			AppBarComponent={AppBar}
			AppBarProps={AppBarProps}
			AppDrawerProps={AppDrawerProps}
		>
			<ErrorBoundary>
				{alertData?.text && !alertData.exludePages?.includes(pageData.id) && !['admin', 'test'].includes(pageData.id) && (
					<AppBanner
						{...alertData}
						rounded
						borderRadius={null}
						marginTop={theme => theme.config.layoutSpacing}
					/>
				)}
				<Outlet context={pageData}/>
				{!options.noFooter && (
					appFooterContent
				)}
				{cookieConsentContent}
			</ErrorBoundary>
		</AppFrame>
	);
}
